import { Card, Button, Row, Col, Form } from 'react-bootstrap';
import { CartContext } from '../CartContext';
import { useContext, useState } from 'react';

function ShirtCard (props) {
    const product = props.product;
    const cart = useContext(CartContext);
    const [selectedSize, setSelectedSize] = useState('S'); // default size
    const selectedProduct = product.sizes.find(size => size.size === selectedSize);
    const productQuantity = cart.getProductQuantity(selectedProduct.id);
    return (
        <Row>
            <Col>
                <Card className='border-0'>
                    <Card.Body>
                        {product.pics}
                        <Card.Title>{product.style}</Card.Title>
                        <Card.Title class="fw-normal mb-3">{product.title}</Card.Title>
                        <Form.Select 
                        aria-label="Select size"
                        style={{ width: '120px' }} 
                        onChange={e => setSelectedSize(e.target.value)}
                        >
                            <option value="S">Small</option>
                            <option value="M">Medium</option>
                            <option value="L">Large</option>
                            <option value="XL">X-Large</option>
                            <option value="XXL">XX-Large</option>
                        </Form.Select>
                        <Card.Text class="mt-2">${product.price}</Card.Text>
                        { productQuantity > 0 ?
                            <>
                                <Form as={Row}>
                                    <Form.Label column="true" sm="6">In Cart: {productQuantity} </Form.Label>
                                    <Col sm="6">
                                        <Button variant='outline-secondary' onClick={() => cart.addOneToCart(selectedProduct.id)} sm="6" className='mx-2'>+</Button>
                                        <Button variant='outline-danger' onClick={() => cart.removeOneFromCart(selectedProduct.id)} sm="6" className='mx-2'>-</Button>
                                    </Col>
                                </Form>
                                <Button onClick={() => cart.deleteFromCart(selectedProduct.id)} variant='outline-danger' className='my-2'>Remove from cart</Button>
                            </>
                            :
                            <Button variant='outline-secondary' onClick={() => cart.addOneToCart(selectedProduct.id)}>Add To Cart</Button>
                        }
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default ShirtCard;