import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Navbar, Modal } from "react-bootstrap";
import { CartContext } from "../CartContext";
import CartProduct from "./CartProduct";

function NavbarComponent() {
  const cart = useContext(CartContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkout = async () => {
    await fetch('/checkout', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({items: cart.items})
    }).then((response) => {
        return response.json();
    }).then((response) => {
        if(response.url) {
            window.location.assign(response.url, '_blank'); // Forwarding user to Stripe
        }
    });
  }

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  return (
    <>
      <Navbar bg="light" className="navbar-light navbar-border-outline px-5" expand="sm">
        {/* <Container className="fluid"> */}
        <Navbar.Brand href="#">
          <Link to="/" className="nav-link" aria-current="page">
            <img src="img/3dseethrough.png" height={130} width={175} alt="/" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse >
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/store" className="nav-link" aria-current="page">
                Store
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" aria-current="page">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" aria-current="page">
                Contact Us
              </Link>
            </li>
          </ul>
          <form d-flex>
            <Button className="me-5" variant="outline-secondary" onClick={handleShow}>Cart ({productsCount} Items)</Button>
          </form>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Shopping Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { productsCount > 0 ?
            <>
              <p>Items in your cart:</p>
              {cart.items.map((currentProduct, Idx) => (
                <CartProduct key={Idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
              ))}
              
              <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>
              <Button variant="outline-success" onClick={checkout} rel="noopener noreferrer">
                Purchase Items!
              </Button>
            </>
          :
            <h1>There are no items in your cart!</h1>
          }
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NavbarComponent;
