import React from 'react'

function About() {
  return (
    <div className="container mt-5 border border-outline border-rounded p-4 px-5">
      <div>
        <p>
          Growing up, Sasou was exposed to an educational system which was lack of cultural diversity from elementary, onwards into the early days of her middle school experiences. Evidence of this lack began to manifest with her 2nd grade teacher's repeated attempts to diminish Sasou’s sense of self worth.
        </p>
        <img src='img/jltcover.png' className='float-end' width={200} height={225} alt='Card image' />
        <p>
          “Blacks are useless...” “People of your race will never be beautiful...” - Susan Holwes-2nd grade teacher
        </p>
        <p>
          "Children, especially in the early years, are like little sponges, absorbing all the information around them and then actively making sense of it."- Jasmin Kumar
        </p>
        <p>
          The worst part about it is that Sasou began to believe these words... Rarely was she witness to any representation even remotely resembling herself in the world around her, and this greatly affected her self-esteem and train of thought. School and extracurricular activities were meaningless. In her attempts to fit in, she lost herself. Never having been given a chance to discover her true self... thrown into a system which had no desire for any measure of diverse beauty to blossom.
        </p>
        <p>
          “ Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle” - Christian Larson
        </p>
        <p>
          Years of self doubt and pity have been overcome to give birth to Sasou's newfound sense of self love. She has made the decision to embrace her new found sense of self love, and share it with the rest of the world.
        </p>
        <p>
          Sasou’s Creation was birthed from a place of fierce determination. It provides unique designs that any person of color can proudly wear to express the love that they, have for their heritage.
        </p>
        <p>
          “If I could help encourage one child, or one adult to be proud to be black, then I can die saying I made an impact.” - Sasou
        </p>
      </div>
  </div>
  )
}

export default About