import { useContext } from "react";
import { CartContext } from "../CartContext";
import { getProductData } from "../ProductsStore";
import { Button } from "react-bootstrap";


function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);

    return (
        <>
            {productData.pics}
            <h3 className="text-center">{productData.title}</h3>
            <h5 className="text-center mb-4">{productData.title2}</h5>
            <div className="row">
                <div className="col">
                    <p style={{fontSize: '1.5em', marginBottom: '0.2em'}}>Item Count: {quantity}</p>
                    <p style={{marginTop: '0.2em'}}>${ (quantity * productData.price).toFixed(2) }</p>
                </div>
                <div className="col text-end">
                    <Button variant="outline-danger" size="sm" onClick={() => cart.deleteFromCart(id)}>Remove</Button>
                </div>
                <hr></hr>
            </div>
        </>
    )
}

export default CartProduct;