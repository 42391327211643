import { Image } from "react-bootstrap";


const productsArray = [
  {
    id: "price_1O2PQ4GY1xsHmbTLLR5gbxLU",
    pics: <Image src='img/jltcoverRS.jpg' fluid/>,
    title: "Just Like That", 
    title2: "Motivational Children's book written by Sasou Calvain",
    price: 8.99
  },
  {
    id: "price_1NOA3mGY1xsHmbTLfTP9yeCT",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Midnight Black - Small)",
    size: "Small",
    price: 24.99
  },
  {
    id: "price_1O7oASGY1xsHmbTLA4MEjvHa",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Midnight Black - Medium)",
    size: "Medium",
    price: 24.99
  },
  {
    id: "price_1O7oC2GY1xsHmbTLaODCHF9W",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Midnight Black - Large)",
    size: "Large",
    price: 24.99
  },
  {
    id: "price_1O7oD9GY1xsHmbTLrdIN1e7S",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Midnight Black - X-Large)",
    size: "X-Large",
    price: 24.99
  },
  {
    id: "price_1O7oEHGY1xsHmbTLdSx2MKXl",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Midnight Black - XX-Large)",
    size: "XX-Large",
    price: 24.99
  },
  {
    id:"price_1NO9z4GY1xsHmbTL8thS45T8",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Black Dark Chocolate - Small)",
    size: "Small",
    price: 24.99
  },
  {
    id:"price_1O7oJ3GY1xsHmbTL5vlFSy3k",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Black Dark Chocolate - Medium)",
    size: "Medium",
    price: 24.99
  },
  {
    id:"price_1O7oJjGY1xsHmbTL3lwzwqcM",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Black Dark Chocolate - Large)",
    size: "Large",
    price: 24.99
  },
  {
    id:"price_1O7oKQGY1xsHmbTLjspm1WYK",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Black Dark Chocolate - X-Large)",
    size: "X-Large",
    price: 24.99
  },
  {
    id:"price_1O7oL4GY1xsHmbTLiWOcXpBM",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(Black Dark Chocolate - XX-Large)",
    size: "XX-Large",
    price: 24.99
  },
  {
    id:"price_1NO9xMGY1xsHmbTLJ61YzZ0t",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(White Dark Chocolate - Small)",
    size: "Small",
    price: 24.99
  },
  {
    id:"price_1O7oMWGY1xsHmbTLEDDQxuJ8",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(White Dark Chocolate - Medium)",
    size: "Medium",
    price: 24.99
  },
  {
    id:"price_1O7oNkGY1xsHmbTLNaFQ6ANW",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(White Dark Chocolate - Large)",
    size: "Large",
    price: 24.99
  },
  {
    id:"price_1O7oP5GY1xsHmbTL06uINrWj",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(White Dark Chocolate - X-Large)",
    size: "X-Large",
    price: 24.99
  },
  {
    id:"price_1O7oPlGY1xsHmbTLjPQqbAmf",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "I Walk With My Ancestors", 
    title2: "(White Dark Chocolate - XX-Large)",
    size: "XX-Large",
    price: 24.99
  },
  {
    id:"price_1NO9q0GY1xsHmbTL6JVALMEt",
    pics: <Image src='img/wds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(White Desert Sand - Small)",
    size: "Small",
    price: 24.99
  },
  {
    id:"price_1O7oTCGY1xsHmbTLJLxCRbQX",
    pics: <Image src='img/wds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(White Desert Sand - Medium)",
    size: "Medium",
    price: 24.99
  },
  {
    id:"price_1O7oTpGY1xsHmbTL64WSe3t2",
    pics: <Image src='img/wds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(White Desert Sand - Large)",
    size: "Large",
    price: 24.99
  },
  {
    id:"price_1O7oUdGY1xsHmbTL5GDQfFjI",
    pics: <Image src='img/wds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(White Desert Sand - X-Large)",
    size: "X-Large",
    price: 24.99
  },
  {
    id:"price_1O7oVuGY1xsHmbTLjl6MyWbG",
    pics: <Image src='img/wds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(White Desert Sand - XX-Large)",
    size: "XX-Large",
    price: 24.99
  },
  {
    id:"price_1NFSnIGY1xsHmbTLB58zqtrv",
    pics: <Image src='img/blkds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(Black Desert Sand - Small)",
    size: "Small",
    price: 24.99
  },
  {
    id:"price_1O7oXCGY1xsHmbTLqXy8Fp2D",
    pics: <Image src='img/blkds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(Black Desert Sand - Medium)",
    size: "Medium",
    price: 24.99
  },
  {
    id:"price_1O7oXtGY1xsHmbTL9EFxg4KH",
    pics: <Image src='img/blkds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(Black Desert Sand - Large)",
    size: "Large",
    price: 24.99
  },
  {
    id:"price_1O7oZ7GY1xsHmbTLzC6AB3YC",
    pics: <Image src='img/blkds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(Black Desert Sand - X-Large)",
    size: "X-Large",
    price: 24.99
  },
  {
    id:"price_1O7oZZGY1xsHmbTLPsDdFV5a",
    pics: <Image src='img/blkds.png' fluid/>,
    title:"I Walk With My Ancestors",
    title2: "(Black Desert Sand - XX-Large)",
    size: "XX-Large",
    price: 24.99
  }
]

function getProductData(id) {
  let productData = productsArray.find(product => product.id === id)
  if (productData == undefined) {
    console.log("Product data does not exist for ID: " + id)
    return undefined;
  }
  return productData;
}

const booksArray = [
  {
    id: "price_1O2PQ4GY1xsHmbTLLR5gbxLU",
    pics: <Image src='img/jltcoverRS.jpg' fluid/>,
    title: "Just Like That", 
    title2: "Motivational Children's book written by Sasou Calvain",
    price: 8.99
  }
]

function getBookData(id) {
  let bookData = booksArray.find(product => product.id === id)
  if (bookData == undefined) {
    console.log("Product data does not exist for ID: " + id)
    return undefined;
  }
  return bookData;
}

const shirtsArray = [
  {
    style: "I Walk With My Ancestors",
    pics: <Image src='img/mblk.png' fluid/>,
    title: "Midnight Black", 
    sizes: [
      { id: 'price_1NOA3mGY1xsHmbTLfTP9yeCT', size: 'S' },
      { id: 'price_1O7oASGY1xsHmbTLA4MEjvHa', size: 'M' },
      { id: 'price_1O7oC2GY1xsHmbTLaODCHF9W', size: 'L' },
      { id: 'price_1O7oD9GY1xsHmbTLrdIN1e7S', size: 'XL' },
      { id: 'price_1O7oEHGY1xsHmbTLdSx2MKXl', size: 'XXL' },
    ],
    price: 24.99
  },
  {
    style: "I Walk With My Ancestors",
    pics: <Image src='img/blkdc.png' fluid/>,
    title: "Black Dark Chocolate", 
    sizes: [
      { id: 'price_1NO9z4GY1xsHmbTL8thS45T8', size: 'S' },
      { id: 'price_1O7oJ3GY1xsHmbTL5vlFSy3k', size: 'M' },
      { id: 'price_1O7oJjGY1xsHmbTL3lwzwqcM', size: 'L' },
      { id: 'price_1O7oKQGY1xsHmbTLjspm1WYK', size: 'XL' },
      { id: 'price_1O7oL4GY1xsHmbTLiWOcXpBM', size: 'XXL' },
    ],
    price: 24.99
  },
  {
    style: "I Walk With My Ancestors",
    pics: <Image src='img/wdc.png' fluid/>,
    title: "White Dark Chocolate", 
    sizes: [
      { id: 'price_1NO9xMGY1xsHmbTLJ61YzZ0t', size: 'S' },
      { id: 'price_1O7oMWGY1xsHmbTLEDDQxuJ8', size: 'M' },
      { id: 'price_1O7oNkGY1xsHmbTLNaFQ6ANW', size: 'L' },
      { id: 'price_1O7oP5GY1xsHmbTL06uINrWj', size: 'XL' },
      { id: 'price_1O7oPlGY1xsHmbTLjPQqbAmf', size: 'XXL' },
    ],
    price: 24.99
  },
  {
    style: "I Walk With My Ancestors",
    pics: <Image src='img/wds.png' fluid/>,
    title: "White Desert Sand", 
    sizes: [
      { id: 'price_1NO9q0GY1xsHmbTL6JVALMEt', size: 'S' },
      { id: 'price_1O7oTCGY1xsHmbTLJLxCRbQX', size: 'M' },
      { id: 'price_1O7oTpGY1xsHmbTL64WSe3t2', size: 'L' },
      { id: 'price_1O7oUdGY1xsHmbTL5GDQfFjI', size: 'XL' },
      { id: 'price_1O7oVuGY1xsHmbTLjl6MyWbG', size: 'XXL' },
    ],
    price: 24.99
  },
  {
    style: "I Walk With My Ancestors",
    pics: <Image src='img/blkds.png' fluid/>,
    title: "Black Desert Sand", 
    sizes: [
      { id: 'price_1NFSnIGY1xsHmbTLB58zqtrv', size: 'S' },
      { id: 'price_1O7oXCGY1xsHmbTLqXy8Fp2D', size: 'M' },
      { id: 'price_1O7oXtGY1xsHmbTL9EFxg4KH', size: 'L' },
      { id: 'price_1O7oZ7GY1xsHmbTLzC6AB3YC', size: 'XL' },
      { id: 'price_1O7oZZGY1xsHmbTLPsDdFV5a', size: 'XXL' },
    ],
    price: 24.99
  },
]

function getShirtData(id) {
  let shirtData = shirtsArray.find(product => product.sizes.id === id)
  if (shirtData == undefined) {
    console.log("Product data does not exist for ID: " + id)
    return undefined;
  }
  return shirtData;
}

export {productsArray, booksArray, shirtsArray, getProductData, getBookData, getShirtData}