import React, { useState } from 'react'
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <div >
      <div className="row mt-3">
        <img src='img/homecover.png' alt='hero' style={{borderRadius: '25px'}} />
      </div>
      <div className='row mt-3'>
        <div className='col-12 mt-3'>
          <h4>
          Our mission is to motivate people of color towards developing self love and awareness through artistic expressions. This is all in hopes of providing a common ground with the formation of unity amongst our people.
          </h4>
        </div>
      </div>
      <hr />
      <div className="row row-cols-1 row-cols-md-2 g-4 mt-4 mb-4">
        <div className="col pe-3">
        <Link to="/books" style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className="card border-0 h-100"
          style={hoverIndex === 0 ? { objectFit: 'contain', transform: 'scale(1.1)', transition: 'transform .2s' } : { objectFit: 'cover', transform: 'scale(1)', transition: 'transform .2s' }} 
          onMouseEnter={() => setHoverIndex(0)} 
          onMouseLeave={() => setHoverIndex(null)}
          >
            <Image src="img/jltcoverRS.jpg" className="card-img-top" fluid />
            <div className="card-body">
              <h5 className="card-title text-center pt-5 mt-4">Books</h5>
            </div>
          </div>
        </Link>
        </div>
        <div className="col ps-3">
        <Link to="/shirts" style={{ color: 'inherit', textDecoration: 'none' }}>
          <div className="card border-0 h-60"
          style={hoverIndex === 1 ? { objectFit: 'contain', transform: 'scale(1.1)', transition: 'transform .2s' } : { objectFit: 'cover', transform: 'scale(1)', transition: 'transform .2s' }} 
          onMouseEnter={() => setHoverIndex(1)} 
          onMouseLeave={() => setHoverIndex(null)}
          >
            <Image src="img/blkdsedit.png" className="card-img-top" fluid />
            <div className="card-body">
              <h5 className="card-title text-center">Shirts</h5>
            </div>
          </div>
        </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
