import React from 'react';
import { Link } from 'react-router-dom';

const FooterComponent = () => {
    return (
        // <div className="container my-5">
            <div className="text-center text-lg-start text-white mt-5" style={{ backgroundColor: '#0A0A0A' }}>
                <section className="d-flex justify-content-between p-4" style={{ backgroundColor: '#8B1A1A' }}>
                    <div className="ms-5">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    <div className='me-5'>
                        <a href="https://www.facebook.com/profile.php?id=100092695636528" className="text-white me-4" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.twitter.com/sasouscreations" className="text-white me-4" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/sasouscreations" className="text-white me-4" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </section>
                <section>
                    <div className="container text-center text-md-start mt-5">
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Sasou's Creations</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }} />
                                <p>
                                Inspiring pride and empowerment within the Black community through creativity and self-discovery.
                                </p>
                            </div>
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Products</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }} />
                                <p>
                                    <Link to="/books" className="col-md-auto"  style={{ color: 'inherit' }}>
                                        Books
                                    </Link>
                                </p>
                                <p>
                                    <Link to="/shirts" className="col-md-auto"  style={{ color: 'inherit' }}>
                                        Shirts
                                    </Link>
                                </p>
                            </div>
                            {/* <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold">Useful links</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }} />
                                <p>
                                    <a href="#!" className="text-white">Your Account</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Become an Affiliate</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Shipping Rates</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-white">Help</a>
                                </p>
                            </div> */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold">Contact</h6>
                                <hr className="mb-4 mt-0 d-inline-block mx-auto" style={{ width: '60px', backgroundColor: '#7c4dff', height: '2px' }} />
                                <p>
                                    <i className="fas fa-home me-2"></i> 
                                    <a href="https://www.google.com/maps/search/?api=1&query=500+S+Federal+Hwy+%23+2622+Hallandale+Beach,+FL+33009,+US" target="_blank" rel="noopener noreferrer" style={{  textDecoration: 'none' }}>
                                        500 S Federal Hwy # 2622 <br></br> Hallandale Beach, FL 33009, US
                                    </a>
                                </p>
                                <p>
                                    <i className="fas fa-envelope me-2"></i> 
                                    <a href="mailto:Creativemasterunleashed@gmail.com"
                                    style={{ textDecoration: 'none' }}
                                    >
                                        Sasouscreations@gmail.com
                                    </a>
                                </p>
                                <p>
                                    <i className="fas fa-phone me-2"></i> 
                                    <a href="tel:(305) 981-6554"
                                    style={{ textDecoration: 'none' }}>
                                        (305) 981-6554
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                    © 2023 SasousCreations.com
                </div>
            </div>
        // </div>
    );
};

export default FooterComponent;
