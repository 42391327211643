import React from "react";

function Cancel() {
    return (
        <div className="container mt-5 mb-5 border border-outline border-rounded p-4 px-5 text-center">
            <div>
                <h1>Your order has been cancelled!</h1>
                <p>
                    We're sad to see you go, but hope to see you again soon!
                </p>
            </div>
        </div>
    )
}

export default Cancel;