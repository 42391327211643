import React from "react";

function Success() {
    return (
        <div className="container mt-5 mb-5 border border-outline border-rounded p-4 px-5">
            <div>
                <h1>Thank you for your purchase!</h1>
                <p>
                    Your order has been successfully placed! We will send you an email confirmation shortly.
                </p>
            </div>
        </div>
    )
}

export default Success;