import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { booksArray, shirtsArray } from "../ProductsStore";
import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";
import ShirtCard from "../components/ShirtCard";

function Store() {
  return (
    <>
      <Container>
        <h1 className="p-4 px-2 justify-content-md-center text-center">All Products</h1>
        <hr />
        <div className="container">
          <div className="row justify-content-md-center mb-4">
            <Link to="/books" className="col-md-auto">
              Books
            </Link>
            <Link to="/shirts" className="col-md-auto">
              Shirts
            </Link>
          </div>
        </div>
        <Row sm={1} md={2} className="g-4 mb-4">
          {booksArray.map((product, idx) => (
            <Col align="center" key={idx} className="w-50">
              <ProductCard product={product}/>
            </Col>
          ))}
        </Row>
        <hr />
        <Row sm={1} md={2} className="g-4">
          {shirtsArray.map((product, idx) => (
            <Col align="center" key={idx} className="w-50">
              <ShirtCard  product={product}/>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default Store;
