import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { booksArray } from "../ProductsStore";
import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";

const Books = () => {
  return (
    <>
      <Container>
        <h1 className="p-4 px-5 text-center">Books</h1>
        <hr />
        <div className="container">
          <div className="row justify-content-md-center mb-4">
            <Link to="/store" className="col-md-auto">
              All Products
            </Link>
          </div>
        </div>
        <Row sm={1} md={2} className="g-4">
          {booksArray.map((product, idx) => (
            <Col align="center" key={idx} className="w-50">
              <ProductCard  product={product}/>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  )
}

export default Books