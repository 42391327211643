import React from 'react'

function Notfound() {
  return (
    <div className="container mt-5 border border-outline border-rounded p-4 px-5 text-center">
      <h1>Page Not Found!</h1>
    </div>
  )
}

export default Notfound