import React from 'react'
import { Image } from "react-bootstrap";

const ProductDetails = () => {
  return (
    <>
    <div className="container">
      <div className="row">
        <div className="col">
          <Image src="img/mblk.png" alt="product" fluid/>
            <div className="row row-cols-4 row-cols-md-4">
              <div className="col">
                <Image src="img/mblk.png" alt="product" fluid/>
              </div>
              <div className="col">
                <Image src="img/mblk.png" alt="product" fluid/>
              </div>
              <div className="col">
                <Image src="img/mblk.png" alt="product" fluid/>
              </div>
              <div className="col">
                <Image src="img/mblk.png" alt="product" fluid/>
              </div>
            </div>
        </div>
        <div className="col">
          <h1>Product Name</h1>
          <p>Product Description</p>
          <select>
            <option>Choose Size</option>
            <option>Small</option>
            <option>Medium</option>
            <option>Large</option>
            <option>X-Large</option>
            <option>XX-Large</option>
          </select>
          <h2 className='mt-3'>Price</h2>
          <button>Add to Cart</button>
        </div>
      </div>
    </div>
    </>
  )
}

export default ProductDetails