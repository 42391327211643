import NavbarComponent from './components/Navbar';
import React from 'react';
import { Route, Routes} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Books from './pages/Books';
import Store from './pages/Store';
import Notfound from './pages/Notfound';
import CartProvider from './CartContext';
import FooterComponent from './components/Footer';
import Shirts from './pages/Shirts';
import ProductDetails from './pages/ProductDetails';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

function App() {
  return (
    <>
    <CartProvider className='bg-light bg-gradient'>
    <div className='py-2 bg-warning text-black container-fluid text-center' >
        Now accepting T-Shirt Pre-Orders! Order by June 1st to secure yours & use code 'juneteenth' for 15% off! All orders shipped out June 8th.
      </div>
      <div className='bg-black text-white container-fluid text-center' >
        Free shipping on orders over $200. Follow <a href="https://www.instagram.com/sasouscreations" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
      @sasouscreations
    </a> for the latest news & updates!
      </div>
        <NavbarComponent></NavbarComponent>
          <Container>
            <Routes>
            {/* path is added to url; element is the destination */}
            {/* ---> / <--- identifies the index page */}
            {/* ---> path='*' <--- is used to indicate invalid url */}
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/store' element={<Store />} />
            <Route path='/shirts' element={<Shirts />} />
            <Route path='/books' element={<Books />} />
            <Route path='/productDetails' element={<ProductDetails />} />
            <Route path='*' element={<Notfound />} />
            <Route path='/success' element={<Success />} />
            <Route path='/cancel' element={<Cancel />} />
          </Routes>
          </Container>
        <FooterComponent></FooterComponent>
    </CartProvider>
    </>
  );
}

export default App;
