import React from 'react'
import { Container } from 'react-bootstrap';
import { Col, Row } from 'react-bootstrap';

function Contact() {
  return (
    <div className="container-fluid text-center" style={{ position: 'relative' }}>
      <Container>
        <Row>
          <Col xs={12} sm={10} md={8} lg={12} className="mx-auto">
            <iframe 
              src="https://docs.google.com/forms/d/e/1FAIpQLSdyN6j14E3vh8l9ME4ERWcRLT4SxXOWSreIX3_qCLA_YfhkfA/viewform?embedded=true" 
              width="100%" 
              height="650" 
              frameBorder="0" 
              marginHeight="0" 
              marginWidth="0"
              title="Contact Form"
            >
              Loading…
            </iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact